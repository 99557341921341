import React, { useEffect, useState } from "react";
import Header from "components/Header";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { GetStationDetailById, GetFinalStationList } from "api/stations";
import { GetSumPackagesByStation } from "api/packages";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridCustomToolbarClientMode } from "components/DataGridCustomToolbar";
import { copyTextToClipboard } from "utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { defaultStation, user } from "localStorage";
import { isMobile } from "react-device-detect";

const PackageListByStation = () => {
  const theme = useTheme();
  const [selectedStation, setSelectedStation] = useState(null);
  const [rows, setRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stationList, setStationList] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const _user = user();
  const _defaultStation = defaultStation();
  // const stationList = _user.stations;

  function RefreshPackagesList(stationId) {
    setRows([]);
    setIsLoading(true);
    GetSumPackagesByStation(stationId).then((res) => {
      if (res.status === 200) {
        setRows(res.data);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (selectedStation) {
      RefreshPackagesList(selectedStation.id);
    }
  }, [selectedStation]);

  useEffect(() => {
    GetFinalStationList().then((res) => {
      if (res && res.status === 200) {
        setStationList(res.data);
        // let arr = res.data;
        // let validStation = [];
        // if (Array.isArray(arr)) {
        //   arr.forEach((finalStation) => {
        //     // console.log("finalStation", finalStation);
        //     _user.stations.forEach((userStation) => {
        //       // console.log("userStation", userStation);
        //       if (finalStation.id === userStation.id)
        //         validStation.push(finalStation);
        //     });
        //   });
        // }
        // if (validStation) setStationList(validStation);
      }
      setSelectedStation(_defaultStation);
    });
  }, []);

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (searchValue) {
        newArr = newArr.filter((element) => {
          return (
            (element._id.customer &&
              element._id.customer.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element._id.customer &&
              element._id.customer.whatsapp
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      }
      setFilteredRows(newArr);
    }
  }

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => params.row._id.customer._id,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.7,
      valueGetter: (params) => params.row._id.customer.name,
    },
    {
      field: "whatsapp",
      headerName: "Whatsapp",
      flex: 1,
      valueGetter: (params) => params.row._id.customer.whatsapp,
    },

    {
      field: "smsMessage",
      headerName: "Message",
      flex: 3,
      hide: isMobile,
    },
    {
      field: "totalPackages",
      headerName: "item(s).",
      flex: 0.5,
    },
    {
      field: "totalShippingFee",
      headerName: "Fee",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "copy",
      headerName: "Copy",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              copyTextToClipboard(cellValues.row.smsMessage);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ContentCopyIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box m={"0 -1.5rem"}>
      <Grid container spacing={1}>
        <Grid mt={"-0.5rem"} item xl={12} md={12} xs={12}>
          {/* Filtering section */}
          <Grid container spacing={2}>
            <Grid item xl={3} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select station</InputLabel>
                <Select
                  label="Select station"
                  value={selectedStation ? selectedStation.id : ""}
                  onChange={(e) => {
                    GetStationDetailById(e.target.value).then((res) => {
                      if (res.status === 200) {
                        setSelectedStation(res.data);
                      }
                    });
                  }}
                  required
                >
                  {stationList &&
                    stationList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid mt={"-0.5rem"} item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            height="73vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            <DataGrid
              loading={isLoading}
              getRowId={(row) => row._id.customer._id}
              rows={filteredRows || []}
              columns={columns}
              rowCount={filteredRows ? filteredRows.length : 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbarClientMode }}
              componentsProps={{
                toolbar: { search, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageListByStation;
