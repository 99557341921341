import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
  Select,
  MenuItem,
  TextField,
  OutlinedInput,
  Grid,
  Switch,
  FormControlLabel,
  Divider,
} from "@mui/material";

import { QueryProvinces, QueryDistrists } from "api/general";
import { GetRouteByProvince, GetRouteList } from "api/routes";
import { StoreCustomer } from "api/customers";
import { GetAgentList } from "api/agent";
import { isAgentUser, playCorrectSound, playIncorrectSound } from "utils";
import { user } from "localStorage";

const NewCustomer = () => {
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [remark, setRemark] = useState("");
  const [routeId, setRouteId] = useState("");
  const [routeList, setRouteList] = useState([]);
  const [isCOD, setIsCOD] = useState(false);
  const [isDeliveryRequired, setIsDeliveryRequired] = useState(false);
  const [isSubCustomer, setIsSubCustomer] = useState(false);
  const [level, setLevel] = useState("1");
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isForwardRequired, setIsForwardRequired] = useState(false);
  const [isSelfOrder, setIsSelfOrder] = useState(false);

  const validflag = true;
  const _user = user();
  const isAgentUser = _user.role === "agent";

  useEffect(() => {
    QueryProvinces().then((res) => setProvinces(res.data));
    GetRouteList().then((res) => {
      setRouteList(res.data);
    });
  }, []);

  useEffect(() => {
    if (provinceId) {
      setDistrictId("");
      setDistricts([]);
      QueryDistrists(provinceId).then((res) => setDistricts(res.data));
      if (!isAgentUser) {
        GetRouteByProvince(provinceId).then((res) => {
          console.log(res.data);
          if (res.data) {
            setRouteId(res.data);
          }
        });
      }
    }
  }, [provinceId]);

  useEffect(() => {
    if (!isSubCustomer) {
      setAgentId("");
    }
  }, [isSubCustomer]);

  useEffect(() => {
    if (isSubCustomer) {
      GetAgentList().then((res) => {
        if (res.data) {
          setAgentList(res.data);
        }
      });
    }
  }, [isSubCustomer]);

  useEffect(() => {
    if (isForwardRequired) {
      setIsDeliveryRequired(false);
    }
  }, [isForwardRequired]);

  useEffect(() => {
    if (isDeliveryRequired) {
      setIsForwardRequired(false);
    }
  }, [isDeliveryRequired]);

  function clearForm() {
    setProvinceId("");
    setDistrictId("");
    setDistricts([]);
    setRouteId("");
    setName("");
    setWhatsapp("");
    setRemark("");
    setAgentList([]);
    setAgentId("");
    setIsCOD(false);
    setIsDeliveryRequired(false);
    setIsForwardRequired(false);
    setIsSubCustomer(false);
    setIsSelfOrder(false);
  }

  const formSubmittedHandler = (e) => {
    e.preventDefault();

    const provinceObj = provinces.find(
      (item) => item.pr_id === provinceId.toString()
    );
    const districtObj = districts.find(
      (item) => item.dt_id === districtId.toString()
    );

    if (isAgentUser) {
      setIsSubCustomer(true);
      setAgentId(_user.agentId);
    }

    StoreCustomer(
      name,
      whatsapp,
      provinceObj,
      districtObj,
      remark,
      routeId,
      validflag,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isSubCustomer,
      agentId,
      level,
      isSelfOrder
    ).then((res) => {
      console.log(res);
      if (res.status === 200) {
        clearForm();
        playCorrectSound();
        Swal.fire({
          title: "Created!",
          icon: "success",
          text: `${name} has been created`,
          timer: 1500,
        });
      } else if (res.status === 400) {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
          showConfirmButton: true,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          icon: "error",
          text: res.message,
          showConfirmButton: true,
        });
      }
    });
  };

  return (
    <Box m={"0 -1.5rem"}>
      <form onSubmit={formSubmittedHandler}>
        <Grid container spacing={2} maxWidth={500}>
          <Grid item xl={6} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="name"
                label="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required={true}
              />
            </FormControl>
          </Grid>
          <Grid item xl={6} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="whatsapp">Whatsapp</InputLabel>
              <OutlinedInput
                type="number"
                id="whatsapp"
                value={whatsapp}
                label="Whatsapp"
                onChange={(e) => {
                  setWhatsapp(e.target.value);
                }}
                required={true}
              />
              <FormHelperText id="whatsapp-helper-text">
                Example: 8562077790708.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Province / ແຂວງ</InputLabel>
              <Select
                id="province"
                value={!provinceId ? "" : provinceId}
                label="Province / ແຂວງ"
                onChange={(event) => setProvinceId(event.target.value)}
                required={true}
              >
                {provinces &&
                  provinces.map(({ pr_id, pr_name, pr_name_en }) => {
                    return (
                      <MenuItem key={pr_id} value={pr_id}>
                        {pr_name} / {pr_name_en}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xl={12} md={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel>District / ເມືອງ</InputLabel>
              <Select
                id="districts"
                // defaultValue=""
                value={!districtId ? "" : districtId}
                label="District / ເມືອງ"
                onChange={(event) => setDistrictId(event.target.value)}
                disabled={!Boolean(provinceId)}
                required={true}
              >
                {districts &&
                  districts.map(({ dt_id, dt_name, dt_name_en }) => {
                    return (
                      <MenuItem key={dt_id} value={dt_id}>
                        {dt_name} / {dt_name_en}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel>Route</InputLabel>
              <Select
                id="route"
                value={!routeId ? "" : routeId}
                label="Route"
                onChange={(event) => setRouteId(event.target.value)}
                required={true}
              >
                {routeList &&
                  routeList.map(({ id, name }) => {
                    return (
                      <MenuItem key={id} value={id}>
                        {id} ({name})
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={6} md={6} xs={6}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={isCOD}
                  onChange={() => setIsCOD(!isCOD)}
                />
              }
              label="COD ເກັບຄ່າບໍລິການປາຍທາງ"
            />
          </Grid>
          <Grid item xl={6} md={6} xs={6}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={isDeliveryRequired}
                  onChange={() => setIsDeliveryRequired(!isDeliveryRequired)}
                />
              }
              label="Delivery ຈັດສົ່ງຮອດເຮືອນ"
            />
          </Grid>

          <Grid item xl={6} md={6} xs={6}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={isForwardRequired}
                  onChange={() => setIsForwardRequired(!isForwardRequired)}
                />
              }
              label="ຕ້ອງໄດ້ຝາກນຳຂົນສົ່ງຕໍ່"
            />
          </Grid>
          <Grid item xl={6} md={6} xs={6}>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={isSelfOrder}
                  onChange={() => setIsSelfOrder(!isSelfOrder)}
                />
              }
              label="ລູກຄ້າສັ່ງເຄື່ອງເອງ"
            />
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <FormControl fullWidth>
              <TextField
                value={remark}
                multiline
                maxRows={5}
                label="Remark / ໝາຍເຫດ"
                // placeholder="Description"
                id="remark"
                onChange={(e) => setRemark(e.target.value)}
                required={isForwardRequired}
              />
            </FormControl>
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <Divider />
          </Grid>
          {!isAgentUser && (
            <Grid item xl={6} md={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={isSubCustomer}
                    onChange={() => setIsSubCustomer(!isSubCustomer)}
                  />
                }
                label="ລູກຄ້າຂອງຕົວແທນ"
              />
            </Grid>
          )}

          {!isAgentUser && isSubCustomer && (
            <Grid item xl={6} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                <Select
                  id="agent"
                  value={!agentId ? "" : agentId}
                  label="ເລືອກຕົວແທນ Agent"
                  onChange={(event) => setAgentId(event.target.value)}
                  required={true}
                >
                  {agentList &&
                    agentList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} - {name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid
            item
            xl={12}
            md={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControl>
              <Button
                variant="contained"
                disabled={!districtId || !provinceId || !routeId}
                type="submit"
                color="secondary"
                size="large"
                sx={{ px: 5 }}
              >
                Submit
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NewCustomer;
