import React from "react";
import { Box } from "@mui/material";
import Header from "components/Header";
import TabsNavigator from "components/TabsNavigator";
import NewPackage from "./newPackage";
// import PackageListItems from "./packageListItems";
import PackageListByStation from "./packageListByStation";
import PackageList from "./packageList";

const Packages = () => {
  const tabItems = [
    {
      text: "Package list (New)",
      component: <PackageList />,
    },
    // {
    //   text: "Package list",
    //   component: <PackageListItems />,
    // },
    {
      text: "New Package",
      component: <NewPackage />,
    },
    // {
    //   text: "Inactive Packages",
    //   component: <InactivePackage />,
    // },
    {
      text: "Station",
      component: <PackageListByStation />
    }
  ];

  return (
    <Box m="0 1rem">
      <Header title="Packages" />
      <TabsNavigator tabItems={tabItems} />
    </Box>
  );
};

export default Packages;
