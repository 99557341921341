import { api } from "./config";

export async function GetAgentList() {
  try {
    const response = await api().get("/agent/list");
    return response;
  } catch (error) {
    console.log(error);
  }
}


export async function GetAgentDetailById(id) {
  try {
    const response = await api().get("agent/detail", {
      params: {
        id: id,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}