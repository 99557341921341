import React, { useEffect } from "react";
import { Search } from "@mui/icons-material";
import {
  IconButton,
  TextField,
  InputAdornment,
  Box,
  InputBase,
  useTheme,
} from "@mui/material";
import {
  GridToolbarDensitySelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { isMobile } from "react-device-detect";

const DataGridCustomToolbar = ({ searchInput, setSearchInput, setSearch }) => {
  const theme = useTheme();
  return (
    <GridToolbarContainer
      sx={{ marginBottom: "0.3rem", marginRight: "-0.3rem" }}
    >
      <FlexBetween width="100%">
        <FlexBetween>
          {isMobile ? (
            <GridToolbarColumnsButton />
          ) : (
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
            </Box>
          )}
        </FlexBetween>
        <FlexBetween
          backgroundColor={theme.palette.background.alt}
          borderRadius="9px"
          gap="3rem"
          p="0.2rem 0.8rem"
        >
          <InputBase
            placeholder="Search..."
            id="search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearch(searchInput);
              }
            }}
          />

          <IconButton>
            <Search />
          </IconButton>
        </FlexBetween>
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export const DataGridCustomToolbarClientMode = ({ search, setSearch }) => {
  const theme = useTheme();
  return (
    <GridToolbarContainer
      sx={{ marginBottom: "0.3rem", marginRight: "-0.3rem" }}
    >
      <FlexBetween width="100%">
        <FlexBetween>
          {isMobile ? (
            <GridToolbarColumnsButton />
          ) : (
            <Box>
              <GridToolbarColumnsButton />
              <GridToolbarDensitySelector />
              <GridToolbarExport />
            </Box>
          )}
        </FlexBetween>

        <FlexBetween
          backgroundColor={theme.palette.background.alt}
          borderRadius="9px"
          gap="3rem"
          p="0.2rem 0.8rem"
        >
          <InputBase
            placeholder="Client Search..."
            id="search"            
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <IconButton>
            <Search />
          </IconButton>
        </FlexBetween>
      </FlexBetween>
    </GridToolbarContainer>
  );
};

export default DataGridCustomToolbar;
