import React, { useEffect, useState } from "react";
import Header from "components/Header";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import CameraAltIcon from "@mui/icons-material/CameraAlt";

import moment from "moment-timezone";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  OutlinedInput,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  FormControlLabel,
  Switch,
  Slide,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FlexBetween from "components/FlexBetween";
import { useTheme } from "@emotion/react";
import {
  GetPackageDetailByTracking,
  GetPackagesByStationAndDate,
  PatchShippingFee,
  scanPackage,
  StorePackage,
} from "api/packages";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import { GetStationDetailById } from "api/stations";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridCustomToolbarClientMode } from "components/DataGridCustomToolbar";
import {
  getScanText,
  playAlertSound,
  playBabySneezeSound,
  playCorrectSound,
  playIncorrectSound,
  playPoliceWhistleSound,
  playReadyBeebSound,
  selectAndFocus,
} from "utils";
import { user, defaultStation } from "localStorage";
import Scanner from "components/Scanner";
import { isMobile } from "react-device-detect";
import { GetRouteListByStation } from "api/routes";
import { CloseOutlined } from "@mui/icons-material";
import { GetCustomersList } from "api/customers";
import { GetAgentList } from "api/agent";
import { StoreTrackingLog } from "api/trackingLogs";
import { isNullOrUndefined } from "html5-qrcode/esm/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Scan = () => {
  const stationList = user().stations;
  const _defaultStation = defaultStation();
  const theme = useTheme();
  const [tracking, setTracking] = useState("");
  const [selectedStation, setSelectedStation] = useState(_defaultStation);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [rows, setRows] = useState(null);
  const [successCount, setSuccessCount] = useState(0);
  const [open, setOpen] = useState(false);

  const [routeId, setRouteId] = useState("");
  const [routeList, setRouteList] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isNewCustomerSelected, setIsNewCustomerSelected] = useState(false);

  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [shippingFee, setShippingFee] = useState(0);
  const [customer, setCustomer] = useState(null);

  const [isDeliveryRequired, setIsDeliveryRequired] = useState(false);
  const [isCOD, setIsCOD] = useState(false);
  const [isSubCustomer, setIsSubCustomer] = useState(false);
  const [level, setLevel] = useState("1");
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isForwardRequired, setIsForwardRequired] = useState(false);
  const [description, setDescription] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isSelfOrder, setIsSelfOrder] = useState(false);
  const status = "Started";

  const _user = user();
  const isAgentUser = _user.role === "agent";

  // Data for front-end
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [filteredRows, setFilteredRows] = useState(null);

  // const [inputValue, setInputValue] = useState('');

  // const isNonMobile = useMediaQuery("(min-width: 600px)");

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  // const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [isInputByCameraScanner, setIsInputByCameraScanner] = useState(false);

  function RefreshPackagesList(from, to, station) {
    GetPackagesByStationAndDate(from, to, station).then((res) => {
      if (res.status === 200) {
        setRows(res.data.packages);
      } else {
        console.log(res.message);
      }
    });
  }

  useEffect(() => {
    if (editDialogOpen) playPoliceWhistleSound();
  }, [editDialogOpen]);

  useEffect(() => {
    GetCustomersList(searchCustomer, undefined, undefined, undefined).then(
      (res) => {
        if (res.status === 200) {
          setCustomerList(res.data.customers);
          let selectedCustomer = res.data.customers.filter((obj) => {
            return obj.name === searchCustomer;
          });

          // Set customer if selectedCustomer is not empty
          if (Object.keys(selectedCustomer).length !== 0) {
            setCustomer(selectedCustomer[0]);
          } else {
            setCustomer(null);
          }
        } else {
          console.log(res.message);
        }
      }
    );
  }, [searchCustomer]);

  useEffect(() => {
    if (isNewCustomerSelected) {
      document.getElementById("shippingFee").select();
      document.getElementById("shippingFee").focus();
      if (customer) {
        if ("default_route" in customer) setRouteId(customer.default_route);
        if ("isCOD" in customer) setIsCOD(customer.isCOD);
        if ("isDeliveryRequired" in customer)
          setIsDeliveryRequired(customer.isDeliveryRequired);
        if ("isForwardRequired" in customer)
          setIsForwardRequired(customer.isForwardRequired);
        if ("isSubCustomer" in customer)
          setIsSubCustomer(customer.isSubCustomer);
        if ("agentId" in customer) setAgentId(customer.agentId);
        if ("level" in customer) setLevel(customer.level);
        if ("isSelfOrder" in customer) setIsSelfOrder(customer.isSelfOrder);
      }
      setIsNewCustomerSelected(false);
    }
  }, [customer, isNewCustomerSelected]);

  useEffect(() => {
    if (isSubCustomer) {
      GetAgentList().then((res) => {
        if (res.data) {
          setAgentList(res.data);
        }
      });
    } else {
      setAgentId("");
    }
  }, [isSubCustomer]);

  useEffect(() => {
    if (isDeliveryRequired) {
      setIsForwardRequired(false);
    }
  }, [isDeliveryRequired]);

  useEffect(() => {
    if (isForwardRequired) {
      setIsDeliveryRequired(false);
      setRemark(customer.remark);
    } else {
      setRemark("");
    }
  }, [isForwardRequired]);

  useEffect(() => {
    let newFromDate = moment(fromDate).format("YYYY-MM-DD");
    let newToDate = moment(toDate).format("YYYY-MM-DD");
    // console.log(`From: ${newFromDate} - To: ${newToDate}`);
    if (selectedStation) {
      RefreshPackagesList(newFromDate, newToDate, selectedStation.id);
    }
  }, [
    fromDate,
    toDate,
    selectedStation,
    // search,
    successCount,
  ]);

  useEffect(() => {
    GetRouteListByStation(selectedStation.id).then((res) => {
      setRouteList(res.data);
    });
    selectAndFocus("tracking");
  }, [selectedStation]);

  const clearForm = () => {
    setTracking("");
    setDescription("");
    setOrderId("");
    setAmount(0);
    setQuantity(1);
    setShippingFee(0);
    setRemark("");
    setRouteId("");
    setIsSelfOrder(false);
    setIsCOD(false);
    setIsForwardRequired(false);
    setIsDeliveryRequired(false);
    setIsSubCustomer(false);
    setAgentId("");
    setCustomer(null);
  };

  const scanPackageSubmited = (tracking) => {
    scanPackage(getScanText(tracking), selectedStation.id).then((res) => {
      if (res && res.status === 200) {
        if (
          selectedStation.id === "VTE" &&
          res.data.package &&
          res.data.package.routeId === "VTE-THK"
        ) {
          playBabySneezeSound(); // to indicate that the scanned item need to be sent to THK branch
        } else {
          playCorrectSound(); // to indicate that the scanned item need to be sent to PHT branch
        }
        // setTracking("");
        clearForm();
        setSuccessCount(successCount + 1);
        Swal.fire({
          icon: "success",
          title: `Success!`,
          text: `ສະແກນພັດສະດຸສຳເລັດແລ້ວ!`,
          timer: 500,
        });
        if (isInputByCameraScanner) {
          setOpen(true);
          setIsInputByCameraScanner(false);
        }
      } else if (res.status === 202) {
        // console.log(selectedStation);
        if (!selectedStation.isSortStation) {
          playIncorrectSound();
          Swal.fire({
            title: res.data.status,
            icon: "warning",
            text: res.data.message,
            timer: 1200,
          });
          if (isInputByCameraScanner) {
            setOpen(true);
            setIsInputByCameraScanner(false);
          } else {
            selectAndFocus("tracking");
          }
          return;
        }
        // In case data not found
        // playAlertSound();

        setEditDialogOpen(true);
      } else {
        playIncorrectSound();
        Swal.fire({
          title: res.data.status,
          icon: "error",
          text: res.data.message,
          timer: 1500,
        });
        if (isInputByCameraScanner) {
          setOpen(true);
          setIsInputByCameraScanner(false);
        } else {
          selectAndFocus("tracking");
        }
      }
    });
  };

  const handleFormSubmit = () => {
    if (open) {
      setIsInputByCameraScanner(true);
      setOpen(false);
    }

    GetPackageDetailByTracking(getScanText(tracking)).then((res) => {
      var packageData = res.data;
      //If it's a sortstation check more details
      if (res.status !== 200 && !selectedStation.isSortStation) {
        playAlertSound();
        Swal.fire({
          title: "ບໍ່ພົບຂໍ້ມູນໃນລະບົບ!",
          icon: "warning",
          text: `ກະລຸນາກວດສອບຄືນອິກເທື່ອ!`,
          showConfirmButton: true,
        });
      } else if (selectedStation.isSortStation) {
        //Ask user to input shipping fee if it's a COD package and Shipping fee is 0
        if (
          res.status === 200 &&
          (packageData.isCOD ||
            packageData.isSubCustomer ||
            packageData.isSelfOrder) &&
          (isNullOrUndefined(packageData.shippingFee) ||
            packageData.shippingFee === 0)
        ) {
          playReadyBeebSound();
          let customerInfo = `${packageData.customer.name} - ${packageData.customer.whatsapp}`;
          let inputValue = "";
          Swal.fire({
            title: "ກະລຸນາລະບຸຄ່າບໍລິການ",
            text: customerInfo,
            input: "number",
            inputAttributes: {
              style:
                "display: block; margin: 20px auto; text-align: center; width: 150px; height: 50px; padding: 10px; font-size: 30px;",
            },
            // inputValue,
            preConfirm: () => {
              inputValue = Swal.getInput().value;
            },
            inputValidator: (value) => {
              if (!value) {
                return "ຕ້ອງລະບຸຄ່າບໍລິການກ່ອນ!";
              } else if (isNaN(value)) {
                return "Please enter a valid number!";
              } else if (value <= 0) {
                return "ຄ່າບໍລິການຕ້ອງຫຼາຍກວ່າ 0 !";
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              PatchShippingFee(
                packageData._id,
                packageData.tracking,
                packageData.orderId,
                packageData.description,
                packageData.remark,
                packageData.amount,
                packageData.quantity,
                inputValue
              ).then((res) => {
                if (res.status === 200) {
                  scanPackageSubmited(tracking);
                } else {
                  Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: `Update package failed, please check in console log!`,
                    showConfirmButton: true,
                  });
                  console.log(res);
                  return;
                }
              });
              // console.log("Input Value: ", inputValue); // Use the input value as needed
            }
          });
        } else {
          scanPackageSubmited(tracking);
        }
      } else if (selectedStation.finalStation) {
        if (!packageData.customer) {
          playAlertSound();
          Swal.fire({
            title: "ກະລຸນາກຳນົດຊື່ລູກຄ້າກ່ອນ!",
            icon: "warning",
            text: `${packageData.tracking} ຍັງບໍ່ມີຂໍ້ມູນລູກຄ້າເທື່ອ ກະລຸນາໄປທີ່ໜ້າ Unassigned Package`,
            showConfirmButton: true,
          });
          return;
        } else if (
          !packageData.isSelfOrder &&
          (!packageData.orderId || packageData.orderId === "")
        ) {
          playAlertSound();
          Swal.fire({
            title: "ກະລຸນາກຳນົດຂໍ້ມູນພັດສະດຸກ່ອນ!",
            icon: "warning",
            text: `${packageData.tracking} ຍັງບໍ່ມີຂໍ້ມູນເລກທີຄຳສັ່ງຊື້ແລະລາຍລະອຽດສິນຄ້າເທື່ອ ກະລຸນາໄປທີ່ໜ້າ Match Package`,
            showConfirmButton: true,
          });
          return;
        } else {
          scanPackageSubmited(tracking);
        }
      } else {
        scanPackageSubmited(tracking);
      }
    });
  };

  const handleNewPackageFormSubmit = (e) => {
    e.preventDefault();
    let customerId = customer ? customer.id : "";
    StorePackage(
      tracking,
      description,
      orderId,
      customerId,
      amount,
      quantity,
      shippingFee,
      routeId,
      status,
      remark,
      false /* paymentStatus */,
      selectedStation.id,
      false /* whatsappStatus */,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isAgentUser ? true : isSubCustomer, // isSubCustomer field
      isAgentUser ? _user.agentId : agentId, // agentId field
      level,
      isSelfOrder,
      _user.email
    ).then((res) => {
      console.log(res);
      let data = res.result;
      if (res.status === 200) {
        clearForm();

        if (
          selectedStation.id === "VTE" &&
          data &&
          data.routeId === "VTE-THK"
        ) {
          playBabySneezeSound();
        } else {
          playCorrectSound();
        }
        // playCorrectSound();
        let account = user().name;
        GetStationDetailById(selectedStation.id).then((res) => {
          if (res.status === 200) {
            StoreTrackingLog(
              data._id,
              data.tracking,
              res.data.name,
              res.data.message,
              data.remark,
              account
            ).then((res) => {
              // console.log(res);
              if (res.status === 200) {
                console.log(`Trackinglog has ben saved for started station.`);
                setSuccessCount(successCount + 1);
                setEditDialogOpen(false);
                if (isInputByCameraScanner) {
                  setOpen(true);
                  setIsInputByCameraScanner(false);
                }
              } else {
                Swal.fire({
                  title: "Error!",
                  icon: "error",
                  text: `Trackinglog saving failed, please check with developer!`,
                  showConfirmButton: true,
                });
              }
            });
          }
        });

        Swal.fire({
          title: "Created!",
          icon: "success",
          text: `${tracking} has been created`,
          timer: 1500,
        });

        if (isInputByCameraScanner) {
          setOpen(true);
          setIsInputByCameraScanner(false);
        }
      } else if (res.status === 400) {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
          showConfirmButton: true,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          icon: "error",
          text: res.message,
          showConfirmButton: true,
        });
      }
    });
  };
  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (searchValue) {
        newArr = newArr.filter((element) => {
          return (
            (element.tracking &&
              element.tracking
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element.description &&
              element.description
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element.customer &&
              element.customer.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element.orderId &&
              element.orderId
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element._id.customer &&
              element._id.customer.whatsapp
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      }

      setFilteredRows(newArr);
    }
  }

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.5,
      valueGetter: (params) => params.row.customer && params.row.customer.name,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 0.7,
      hide: true,
    },
    {
      field: "tracking",
      headerName: "Tracking",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      hide: isMobile,
    },
    {
      field: "routeId",
      headerName: "Route ID",
      flex: 0.3,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
      hide: true,
    },

    {
      field: "station",
      headerName: "Station",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.4,
      hide: true,
    },
    {
      field: "isCOD",
      headerName: "COD",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isCOD ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "shippingFee",
      headerName: "ຄ່າບໍລິການ",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "finalShippingFee",
      headerName: "Final Fee",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "isSelfOrder",
      headerName: "ສັ່ງເອງ",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSelfOrder ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },

    {
      field: "isSubCustomer",
      headerName: "ລູກຄ້າຕົວແທນ",
      flex: 0.3,
      hide: isMobile || isAgentUser,
      renderCell: (cellValues) => {
        return cellValues.row.isSubCustomer ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "agentId",
      headerName: "ຕົວແທນ",
      flex: 0.3,
      hide: isMobile || isAgentUser,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.7,
      hide: isMobile,
      valueGetter: (params) =>
        moment(params.row.updatedAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.7,
      hide: true,
      valueGetter: (params) =>
        moment(params.row.createdAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
  ];

  return (
    <Box m="0 1rem">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Header title="Scan package" />
        </Grid>
        <Grid item xl={8} md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={3} md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Select station</InputLabel>
                <Select
                  label="Select station"
                  value={selectedStation ? selectedStation.id : ""}
                  onChange={(e) => {
                    GetStationDetailById(e.target.value).then((res) => {
                      if (res.status === 200) {
                        setSelectedStation(res.data);
                        selectAndFocus("tracking");
                        // console.log("Select station", res.data);
                      }
                    });
                  }}
                  required
                >
                  {stationList &&
                    stationList.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {id} ({name})
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={3} md={6} xs={12}>
              <Box>
                <FormControl fullWidth>
                  <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="9px"
                    gap="3rem"
                    p="0.5rem 1rem"
                  >
                    <InputBase
                      placeholder="Tracking number"
                      id="tracking"
                      autoFocus
                      value={tracking}
                      onChange={(e) => setTracking(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setTracking(getScanText(tracking));
                          // formSubmitHandler();
                          handleFormSubmit();
                        }
                      }}
                    />
                    {!isMobile ? (
                      <IconButton onClick={() => handleFormSubmit}>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setOpen(true)}>
                        <CameraAltIcon />
                      </IconButton>
                    )}
                  </FlexBetween>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={4} md={4} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Filter From"
                    inputFormat="DD/MM/yyyy"
                    value={fromDate}
                    maxDate={toDate}
                    onChange={(newValue) => setFromDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xl={6} md={6} xs={6}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Filter To"
                    inputFormat="DD/MM/yyyy"
                    minDate={fromDate}
                    value={toDate}
                    onChange={(newValue) => setToDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            mt={"-1rem"}
            height={"75vh"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },

              marginLeft: isMobile ? "-0.5rem" : "0",
              marginRight: isMobile ? "-0.5rem" : "0",
            }}
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={filteredRows || []}
              columns={columns}
              rowCount={filteredRows ? filteredRows.length : 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbarClientMode }}
              componentsProps={{
                toolbar: { search, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Scanner
        open={open}
        setOpen={setOpen}
        onDecode={(result) => {
          if (result) {
            console.log(result);
            setTracking(() => getScanText(result));
            handleFormSubmit();
          }
        }}
      />

      {/* Edit Package Dialog  */}
      <Box>
        <Dialog
          open={editDialogOpen}
          TransitionComponent={Transition}
          maxWidth="sm"
          keepMounted
          onClose={() => {
            setEditDialogOpen(false);
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <form onSubmit={handleNewPackageFormSubmit}>
            <DialogTitle>
              New Package
              <IconButton
                aria-label="close"
                onClick={() => setEditDialogOpen(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={2}>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      onInput={(e) => setSearchCustomer(e.target.value)}
                      onChange={(e, value) => {
                        if (value) {
                          setIsNewCustomerSelected(true);
                          setCustomer(value);
                        }
                      }}
                      disablePortal
                      id="combo-box-customer"
                      options={customerList}
                      value={customer}
                      isOptionEqualToValue={(option, value) => {
                        return option._id === value._id;
                      }}
                      // getOptionSelected={(option, value) =>
                      //   value && option._id === value._id
                      // }
                      getOptionLabel={(option) =>
                        option.name + " - " + option.whatsapp || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Customer" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Route</InputLabel>
                    <Select
                      id="route"
                      value={!routeId ? "" : routeId}
                      label="Route"
                      onChange={(event) => setRouteId(event.target.value)}
                      required
                    >
                      {routeList &&
                        routeList.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {id} ({name})
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <InputLabel>COD ເກັບຄ່າບໍລິການປາຍທາງ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isCOD}
                        onChange={() => setIsCOD(!isCOD)}
                      />
                    }
                    label={isCOD ? "ເກັບ" : "ບໍ່ເກັບ"}
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <InputLabel>Delivery ຈັດສົ່ງຮອດເຮືອນ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isDeliveryRequired}
                        onChange={() =>
                          setIsDeliveryRequired(!isDeliveryRequired)
                        }
                      />
                    }
                    label={isDeliveryRequired ? "ຈັດສົ່ງ" : "ບໍ່ຈັດສົ່ງ"}
                  />
                </Grid>

                <Grid item xl={6} md={6} xs={6}>
                  <InputLabel>ຝາກນຳຂົນສົ່ງຕໍ່</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!customer}
                        color="secondary"
                        checked={isForwardRequired}
                        onChange={() =>
                          setIsForwardRequired(!isForwardRequired)
                        }
                      />
                    }
                    label={isForwardRequired ? "ຝາກ" : "ບໍ່ຝາກ"}
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <InputLabel>ລູກຄ້າສັ່ງເຄື່ອງເອງ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isSelfOrder}
                        onChange={() => setIsSelfOrder(!isSelfOrder)}
                      />
                    }
                    label={isSelfOrder ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                  />
                </Grid>
                {!isAgentUser && (
                  <Grid item xl={6} md={6} xs={6}>
                    <InputLabel>ລູກຄ້າຂອງຕົວແທນ</InputLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          checked={isSubCustomer}
                          onChange={() => setIsSubCustomer(!isSubCustomer)}
                        />
                      }
                      label={isSubCustomer ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                    />
                  </Grid>
                )}
                {!isAgentUser && isSubCustomer && (
                  <Grid item xl={6} md={6} xs={6}>
                    <FormControl fullWidth>
                      <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                      <Select
                        id="agent"
                        value={!agentId ? "" : agentId}
                        label="ເລືອກຕົວແທນ Agent"
                        onChange={(event) => setAgentId(event.target.value)}
                        required={true}
                      >
                        {agentList &&
                          agentList.map(({ id, name }) => {
                            return (
                              <MenuItem key={id} value={id}>
                                {id} - {name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xl={12} md={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Tracking Number</InputLabel>
                    <OutlinedInput
                      label="Tracking Number"
                      id="trackingNewPackage"
                      value={tracking}
                      required={true}
                      disabled
                      onChange={(e) => setTracking(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          setTracking(getScanText(tracking));
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Order ID</InputLabel>
                    <OutlinedInput
                      type="text"
                      label="Order ID"
                      value={orderId}
                      id="orderId"
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Description"
                      value={description}
                      id="decscription"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      maxRows={5}
                      label="Remark"
                      value={remark}
                      id="remark"
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Amount</InputLabel>
                    <OutlinedInput
                      type="number"
                      label="Amount"
                      value={amount}
                      id="amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Quantity</InputLabel>
                    <OutlinedInput
                      type="number"
                      value={quantity}
                      label="Quantity"
                      id="quantity"
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Shipping Fee</InputLabel>
                    <OutlinedInput
                      required={isCOD || isSubCustomer || isSelfOrder}
                      value={shippingFee === 0 ? "" : shippingFee}
                      type="number"
                      label="Shipping Fee"
                      id="shippingFee"
                      onChange={(e) => setShippingFee(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={3}
                mb={2}
                mr={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item xl={3} md={3} xs={4}>
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="secondary"
                    >
                      Save
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Scan;
