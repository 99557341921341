import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";

const TabsNavigator = ({ tabItems }) => {
  const [activeTab, setActiveTab] = useState(0); // Initialize with the first tab value as a number
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={activeTab.toString()}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabItems.map(({ text }, index) => {
              const isActive = activeTab.toString() === index.toString();
              return (
                <Tab
                  key={index.toString()}
                  value={index.toString()}
                  label={text}
                  sx={{
                    backgroundColor: isActive
                      ? theme.palette.secondary[300]
                      : theme.palette.grey[200],
                    marginTop: "0.5rem",
                    minHeight: 30,
                  }}
                />
              );
            })}
          </TabList>
        </Box>
        {tabItems.map(({ component }, index) => {
          return (
            <TabPanel key={index.toString()} value={index.toString()}>
              {component}
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
};

export default TabsNavigator;
