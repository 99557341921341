import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { Box, Grid, Divider, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { SendMessage } from "api/whatsapp";
import {
  GetSumPackagesForAgent,
  PatchPackageAgentWhatsappStatus,
} from "api/packages";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridCustomToolbarClientMode } from "components/DataGridCustomToolbar";
import {
  copyTextToClipboard,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import { user } from "localStorage";

const Agent = () => {
  const theme = useTheme();
  const [rows, setRows] = useState(null);
  // const [successCount, setSuccessCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [filteredRows, setFilteredRows] = useState(null);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");

  const _user = user();

  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      // if (searchValue) {
      //   newArr = newArr.filter((element) => {
      //     return (
      //       (element._id.customer.name &&
      //         element._id.customer.name
      //           .toLowerCase()
      //           .includes(searchValue.toLowerCase())) ||
      //       (element._id.customer.whatsapp &&
      //         element._id.customer.whatsapp
      //           .toLowerCase()
      //           .includes(searchValue.toLowerCase()))
      //     );
      //   });
      // }
      setFilteredRows(newArr);
    }
  }

  function deleteRow(dataArr, rowId) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (rowId) {
        newArr = newArr.filter((element) => {
          return element._id.agentId + element._id.station !== rowId;
        });
      }
      setFilteredRows(newArr);
    }
  }

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  useEffect(() => {
    setIsLoading(true);
    GetSumPackagesForAgent().then((res) => {
      if (res && res.status === 200) {
        setRows(res.data);
        setIsLoading(false);
      }
    });
  }, []);

  const sendWhatsappMessage = (message, dataObj, rows) => {
    setIsSending(true);
    let phone = dataObj.agent.whatsapp;

    SendMessage(phone, message).then((res) => {
      if (res && res.status === 200) {
        // console.log(res.data.message);
        const packageDetail = dataObj.packageDetail;
        // console.log(packageDetail);
        const whatsappStatus = false;
        const agentWhatsappStatus = true;
        for (let index = 0; index < packageDetail.length; index++) {
          // console.log(`index: ${index} data.lengthL: ${data.packageDetail.length - 1}` )
          PatchPackageAgentWhatsappStatus(
            packageDetail[index]._id,
            packageDetail[index].tracking,
            agentWhatsappStatus,
            whatsappStatus
          ).then((res) => {
            console.log("🚀 ~ ).then ~ res:", res);

            if (res.status === 200 && index === packageDetail.length - 1) {
              playCorrectSound();
              Swal.fire({
                text: "Whatsapp sending success",
                icon: "success",
                position: "top-right",
                timer: 1200,
                toast: true,
                showConfirmButton: false,
              });
              var rowId = dataObj._id.agentId + dataObj._id.station;
              deleteRow(rows, rowId);
              console.log(`Packages has been updated`);
            }
            if (res.status !== 200) {
              playAlertSound();
              console.log(res.message);
            }
          });
        }
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res.data.message,
          timer: 3000,
        });
        setIsSending(false);
      }
      setIsSending(false);
    });
  };

  const updatePackage = (rows, dataObj) => {
    playAlertSound();
    Swal.fire({
      title: `ສົ່ງຂໍ້ຄວາມແຈ້ງ ${dataObj.agent.name} ແລ້ວບໍ?`,
      text: `Have you notified ${dataObj.agent.name} yet?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: " Yes ",
      cancelButtonText: "Not yet",
    }).then((result) => {
      if (result.isConfirmed) {
        const packageDetail = dataObj.packageDetail;
        const whatsappStatus = false;
        const agentWhatsappStatus = true;
        for (let index = 0; index < packageDetail.length; index++) {
          PatchPackageAgentWhatsappStatus(
            packageDetail[index]._id,
            packageDetail[index].tracking,
            agentWhatsappStatus,
            whatsappStatus
          ).then((res) => {
            if (
              res.status === 200 &&
              index === dataObj.packageDetail.length - 1
            ) {
              playCorrectSound();
              var rowId = dataObj._id.agentId + dataObj._id.station;
              deleteRow(rows, rowId);
            }
            if (res.status !== 200) {
              console.log(res);
            }
          });
        }
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => params.row._id.agentId + params.row._id.station,
      hide: true,
    },
    {
      field: "stationId",
      headerName: "Station",
      flex: 0.5,
      valueGetter: (params) => params.row._id.station,
      hide: false,
    },
    {
      field: "agentId",
      headerName: "Agent ID",
      flex: 0.5,
      valueGetter: (params) => params.row._id.agentId,
      hide: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => params.row.agent.name,
    },
    {
      field: "whatsapp",
      headerName: "Whatsapp",
      flex: 1,
      valueGetter: (params) => params.row.agent.whatsapp,
    },

    {
      field: "smsMessage",
      headerName: "Message",
      flex: 3,
      hide: isMobile,
    },
    {
      field: "totalPackages",
      headerName: "item(s).",
      flex: 0.5,
    },
    {
      field: "totalShippingFee",
      headerName: "Fee",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "copy",
      headerName: "Copy",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              copyTextToClipboard(cellValues.row.smsMessage);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ContentCopyIcon />
          </IconButton>
        );
      },
    },
    {
      field: "send",
      headerName: "Send",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            disabled={isSending}
            onClick={() => {
              sendWhatsappMessage(
                cellValues.row.smsMessage,
                cellValues.row,
                rows
              );
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <SendIcon />
          </IconButton>
        );
      },
    },
    {
      field: "success",
      headerName: "Success",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              updatePackage(filteredRows, cellValues.row);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <CheckIcon />
          </IconButton>
        );
      },
    },
  ];

  // Conditionally filter columns based on isMobile state
  const filteredColumns =
    _user.role === "agent"
      ? columns.filter((column) => column.field !== "send")
      : columns;

  return (
    <Box m="0 1rem">
      <Grid container spacing={2}>
        <Grid item xl={12} md={12} xs={12}>
          <Header
            title="Agent"
            subtitle="ສົ່ງຂໍ້ຄວາມແຈ້ງເຕືອນທາງ WhatsApp ໃຫ້ບັນດາຕົວແທນ Agents"
          />
        </Grid>

        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            // m={isMobile ? " 0 -0.5rem" : "0"}
            mt={-2}
            height="74vh"
            // width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
              marginLeft: { isMobile },
            }}
          >
            <DataGrid
              loading={isLoading}
              getRowId={(row) => row._id.station + row._id.agentId}
              rows={filteredRows || []}
              columns={filteredColumns}
              rowCount={filteredRows ? filteredRows.length : 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbarClientMode }}
              componentsProps={{
                toolbar: { search, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Agent;
