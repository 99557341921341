import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { QueryProvinces, QueryDistrists } from "api/general";
import {
  PatchCustomer,
  DeleteCustomer,
  GetCustomersList,
  GetAllCustomers,
} from "api/customers";
import {
  Box,
  useTheme,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  styled,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  Link,
  Grid,
  Divider,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import { useGetCustomersQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar, {
  DataGridCustomToolbarClientMode,
} from "components/DataGridCustomToolbar";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { GetRouteList } from "api/routes";
import { CloseOutlined } from "@mui/icons-material";
import { GetAgentList } from "api/agent";
import { playCorrectSound, playIncorrectSound } from "utils";
import FlexBetween from "components/FlexBetween";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  zIndex: 1,
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CustomerListNew = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [customerId, setCustomerId] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState("");
  const [districts, setDistricts] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [remark, setRemark] = useState("");
  const [validflag, setValidflag] = useState(true);
  const [routeId, setRouteId] = useState("");
  const [routeList, setRouteList] = useState([]);
  const [isDeliveryRequired, setIsDeliveryRequired] = useState(false);
  const [isCOD, setIsCOD] = useState(false);
  const [isSubCustomer, setIsSubCustomer] = useState(false);
  const [level, setLevel] = useState("1");
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isForwardRequired, setIsForwardRequired] = useState(false);
  const [isSelfOrder, setIsSelfOrder] = useState(false);

  const [filteredRows, setFilteredRows] = useState(null);

  useEffect(() => {
    QueryProvinces().then((res) => setProvinces(res.data));
    GetRouteList().then((res) => {
      setRouteList(res.data);
    });
  }, []);

  useEffect(() => {
    if (provinceId) {
      setDistrictId("");
      setDistricts([]);
      QueryDistrists(provinceId).then((res) => setDistricts(res.data));
    }
  }, [provinceId]);

  useEffect(() => {
    if (isSubCustomer) {
      GetAgentList().then((res) => {
        if (res.data) {
          setAgentList(res.data);
        }
      });
    } else {
      setAgentId("");
    }
  }, [isSubCustomer]);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");

  // values to set in DataGrid
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  function RefreshCutomersList() {
    setIsLoading(true);
    GetAllCustomers().then((res) => {
      if (res.status === 200) {
        setRows(res.data.customers);
        setTotalCount(res.data.total);
        setIsLoading(false);
      } else {
        console.log(res.message);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    RefreshCutomersList();
  }, []);

  useEffect(() => {
    if (isForwardRequired) {
      setIsDeliveryRequired(false);
    }
  }, [isForwardRequired]);

  useEffect(() => {
    if (isDeliveryRequired) {
      setIsForwardRequired(false);
    }
  }, [isDeliveryRequired]);

  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (searchValue) {
        newArr = newArr.filter((element) => {
          return (
            (element.name &&
              element.name.toLowerCase().includes(searchValue.toLowerCase())) ||
            (element.whatsapp &&
              element.whatsapp
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      }
      setFilteredRows(newArr);
    }
  }

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  const buttonEditHandler = (event, cellValues) => {
    const {
      _id,
      name: customerName,
      whatsapp,
      province,
      district,
      remark = "",
      validflag,
      default_route,
      isCOD,
      isForwardRequired,
      isDeliveryRequired,
      isSubCustomer,
      agentId = "",
      isSelfOrder,
    } = cellValues.row;

    setProvinceId(province.pr_id);
    QueryDistrists(province.pr_id).then((res) => {
      setDistricts(res.data);
      setDistrictId(district.dt_id);
    });

    setName(customerName);
    setWhatsapp(whatsapp);
    setCustomerId(_id);
    setRemark(remark);
    setValidflag(validflag);
    setRouteId(default_route);
    setEditDialogOpen(true);
    setIsCOD(isCOD);
    setIsForwardRequired(isForwardRequired);
    setIsDeliveryRequired(isDeliveryRequired);
    setIsSubCustomer(isSubCustomer);
    setAgentId(agentId);
    setIsSelfOrder(isSelfOrder);
  };

  const buttonDeleteHandler = (event, cellValues) => {
    Swal.fire({
      title: `Delete ${cellValues.row.name} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCustomer(cellValues.row._id).then((res) => {
          if (res.status === 200) {
            // DELETE SELECTED ROW FROM ARRAY "data.customers"
            setRows(() => {
              return rows.filter((item) => item._id !== cellValues.id);
            });

            Swal.fire({
              title: "Deleted!",
              text: res.message,
              icon: "success",
              timer: 1500,
            });
          } else if (res.status === 400) {
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: `Status ${res.status} - ${res.data.message}`,
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: res.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };

  const formSubmittedHandler = (e) => {
    e.preventDefault();
    const provinceObj = provinces.find(
      (item) => item.pr_id === provinceId.toString()
    );
    const districtObj = districts.find(
      (item) => item.dt_id === districtId.toString()
    );

    PatchCustomer(
      customerId,
      name,
      whatsapp,
      provinceObj,
      districtObj,
      validflag,
      remark,
      routeId,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isSubCustomer,
      agentId,
      level,
      isSelfOrder
    ).then((res) => {
      if (res.status === 200) {
        // update object data in rows
        let objIndex = rows.findIndex((obj) => obj._id === customerId);
        rows[objIndex].name = name;
        rows[objIndex].whatsapp = whatsapp;
        rows[objIndex].province = provinceObj;
        rows[objIndex].district = districtObj;
        rows[objIndex].validflag = validflag;
        rows[objIndex].remark = remark;
        rows[objIndex].default_route = routeId;
        rows[objIndex].isCOD = isCOD;
        rows[objIndex].isDeliveryRequired = isDeliveryRequired;
        rows[objIndex].isForwardRequired = isForwardRequired;
        rows[objIndex].isSubCustomer = isSubCustomer;
        rows[objIndex].agentId = agentId;
        rows[objIndex].level = level;
        rows[objIndex].isSelfOrder = isSelfOrder;

        playCorrectSound();
        setEditDialogOpen(false);
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
      } else if (res.status === 400) {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <Link
            component="button"
            color="secondary"
            underline="hover"
            onClick={() => navigate(`/customer/${cellValues.row.id}`)}
          >
            {cellValues.row.name}
          </Link>
        );
      },
    },
    {
      field: "whatsapp",
      headerName: "WhatsApp",
      flex: 0.7,
    },
    {
      field: "default_route",
      headerName: "Default Route",
      flex: 0.5,
      hide: false,
    },
    {
      field: "isCOD",
      headerName: "COD",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isCOD ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isDeliveryRequired",
      headerName: "ຈັດສົ່ງຮອດເຮືອນ",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isDeliveryRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },

    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueGetter: (params) => {
        let address;
        if (isMobile) {
          address = params.row.province.pr_name;
        } else {
          address = params.row.province.pr_name;
          address += params.row.district.dt_name
            ? ", " + params.row.district.dt_name
            : "";
        }

        return address;
      },
      hide: true,
    },
    {
      field: "isForwardRequired",
      headerName: "ຝາກຂົນສົ່ງຕໍ່",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isForwardRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "remark",
      headerName: "Remark",
      flex: 1,
      hide: isMobile,
    },
    {
      field: "isSelfOrder",
      headerName: "ສັ່ງເຄື່ອງເອງ",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSelfOrder ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isSubCustomer",
      headerName: "ລູກຄ້າຂອງຕົວແທນ",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSubCustomer ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "agentId",
      headerName: "ລະຫັດຕົວແທນ",
      flex: 0.5,
      hide: isMobile,
    },
    {
      field: "validflag",
      headerName: "Validflag",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.validflag ? (
          <ToggleOnIcon color="secondary" />
        ) : (
          <ToggleOffIcon />
        );
      },
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 0.2,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outline"
            onClick={(event) => {
              buttonEditHandler(event, cellValues);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ModeEditOutlineIcon />
          </IconButton>
        );
      },
    },

    {
      field: "del",
      headerName: "Delete",
      flex: 0.3,
      hide: true,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={(event) => buttonDeleteHandler(event, cellValues)}
          >
            <ClearOutlinedIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box
      // width="100%"
      m={"-1.5rem"}
      height="80vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.primary.light,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <DataGrid
        loading={isLoading}
        getRowId={(row) => row._id}
        rows={filteredRows || []}
        columns={columns}
        rowCount={filteredRows ? filteredRows.length : 0}
        rowsPerPageOptions={[20, 50, 100]}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="client"
        sortingMode="client"
        filterMode="client"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
        components={{ Toolbar: DataGridCustomToolbarClientMode }}
        componentsProps={{
          toolbar: { search, setSearch },
        }}
      />
      {/* Edit dialog section */}
      <Box>
        <Dialog
          open={editDialogOpen}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullScreen={isMobile}
          keepMounted
          onClose={() => {
            setEditDialogOpen(false);
            // setCustomer(null);
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <form onSubmit={formSubmittedHandler}>
            <DialogTitle>
              Edit Customer
              <IconButton
                aria-label="close"
                onClick={() => setEditDialogOpen(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={2}>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input
                      id="name"
                      aria-describedby="name-helper-text"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required={true}
                      value={name}
                    />
                    <FormHelperText id="name-helper-text">
                      Customer name.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="whatsapp">Whatsapp</InputLabel>
                    <Input
                      type="number"
                      id="whatsapp"
                      aria-describedby="whatsapp-helper-text"
                      onChange={(e) => {
                        setWhatsapp(e.target.value);
                      }}
                      required={true}
                      value={whatsapp}
                    />
                    <FormHelperText id="whatsapp-helper-text">
                      Whatsapp number example: 8562077790708.
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth={true} margin="normal">
                    <InputLabel>Province / ແຂວງ</InputLabel>
                    <Select
                      id="province"
                      defaultValue=""
                      value={!provinceId ? "" : provinceId}
                      label="Province / ແຂວງ"
                      onChange={(event) => setProvinceId(event.target.value)}
                      required={true}
                    >
                      {provinces &&
                        provinces.map(({ pr_id, pr_name, pr_name_en }) => {
                          return (
                            <MenuItem key={pr_id} value={pr_id}>
                              {pr_name} / {pr_name_en}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth={true} margin="normal">
                    <InputLabel>District / ເມືອງ</InputLabel>
                    {districts && (
                      <Select
                        id="districts"
                        // defaultValue=""
                        defaultValue=""
                        value={districtId === "" ? "" : districtId}
                        label="District / ເມືອງ"
                        onChange={(event) => setDistrictId(event.target.value)}
                        disabled={!Boolean(provinceId)}
                        required={true}
                      >
                        {districts &&
                          districts.map(({ dt_id, dt_name, dt_name_en }) => {
                            return (
                              <MenuItem key={dt_id} value={dt_id}>
                                {dt_name} / {dt_name_en}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Route</InputLabel>
                    <Select
                      id="route"
                      value={!routeId ? "" : routeId}
                      label="Route"
                      onChange={(event) => setRouteId(event.target.value)}
                      required
                    >
                      {routeList &&
                        routeList.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {id} - {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isCOD}
                        onChange={() => setIsCOD(!isCOD)}
                      />
                    }
                    label="COD ເກັບຄ່າບໍລິການປາຍທາງ"
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isDeliveryRequired}
                        onChange={() =>
                          setIsDeliveryRequired(!isDeliveryRequired)
                        }
                      />
                    }
                    label="Delivery ຈັດສົ່ງຮອດເຮືອນ"
                  />
                </Grid>

                <Grid item xl={6} md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isForwardRequired}
                        onChange={() =>
                          setIsForwardRequired(!isForwardRequired)
                        }
                      />
                    }
                    label="ຕ້ອງໄດ້ຝາກນຳຂົນສົ່ງຕໍ່"
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isSelfOrder}
                        onChange={() => setIsSelfOrder(!isSelfOrder)}
                      />
                    }
                    label="ລູກຄ້າສັ່ງເຄື່ອງເອງ"
                  />
                </Grid>
              </Grid>
              <Grid mt={2} item xl={12} md={12} xs={12}>
                <FormControl fullWidth>
                  <TextField
                    value={remark}
                    multiline
                    maxRows={5}
                    label="Remark / ໝາຍເຫດ"
                    // placeholder="Description"
                    id="remark"
                    onChange={(e) => setRemark(e.target.value)}
                    required={isForwardRequired}
                  />
                </FormControl>
              </Grid>
              <Grid item mt={2} mb={2} xl={12} md={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item xl={6} md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={isSubCustomer}
                      onChange={() => setIsSubCustomer(!isSubCustomer)}
                    />
                  }
                  label="ລູກຄ້າຂອງຕົວແທນ"
                />
              </Grid>
              {isSubCustomer && (
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                    <Select
                      id="agent"
                      value={!agentId ? "" : agentId}
                      label="ເລືອກຕົວແທນ Agent"
                      onChange={(event) => setAgentId(event.target.value)}
                      required={true}
                    >
                      {agentList &&
                        agentList.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {id} - {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "1rem",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={validflag}
                    onChange={() => setValidflag(!validflag)}
                  />
                }
                label={validflag ? "ເປີດໃຊ້ງານ" : "ປີດໃຊ້ງານ"}
              />
              <FormControl>
                <Button
                  disabled={!districtId || !provinceId}
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: theme.palette.secondary[300],
                    color: theme.palette.primary[500],
                    padding: "10px 50px",
                    m: "15px",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary[600],
                      color: theme.palette.primary[500],
                    },
                  }}
                >
                  Save changes
                </Button>
              </FormControl>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default CustomerListNew;
