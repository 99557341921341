import React, { useEffect, useState } from "react";
import Header from "components/Header";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  IconButton,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";

import { GetStationDetailById, GetFinalStationList } from "api/stations";
import { SendMessage } from "api/whatsapp";
import { PatchPackageSomeInfo, GetSumPackagesForWhatsApp } from "api/packages";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridCustomToolbarClientMode } from "components/DataGridCustomToolbar";
import {
  copyTextToClipboard,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import { defaultStation, user } from "localStorage";

const WhatsApp = () => {
  const theme = useTheme();
  const [stationList, setStationList] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [rows, setRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [filteredRows, setFilteredRows] = useState(null);

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");

  const _user = user();
  const _defaultStation = defaultStation();

  function RefreshPackagesList(station) {
    setIsLoading(true);
    GetSumPackagesForWhatsApp(station).then((res) => {
      if (res.status === 200) {
        setRows(res.data);
      } else {
        setRows([]);
      }
      setIsLoading(false);
    });
  }

  function filterPackage(dataArr, searchValue) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (searchValue) {
        newArr = newArr.filter((element) => {
          return (
            (element._id.customer.name &&
              element._id.customer.name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (element._id.customer.whatsapp &&
              element._id.customer.whatsapp
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
          );
        });
      }
      setFilteredRows(newArr);
    }
  }

  function deleteRow(dataArr, rowId) {
    if (dataArr) {
      let newArr = dataArr.slice();
      if (rowId) {
        newArr = newArr.filter((element) => {
          return element._id.customer.id !== rowId;
        });
      }
      setFilteredRows(newArr);
    }
  }

  useEffect(() => {
    filterPackage(rows, search);
  }, [rows, search]);

  useEffect(() => {
    if (selectedStation) {
      RefreshPackagesList(selectedStation.id);
    }
  }, [selectedStation]);

  useEffect(() => {
    GetFinalStationList().then((res) => {
      setStationList(res.data);
      if (res && res.status === 200) {
        setStationList(res.data);
        // let arr = res.data;
        // let validStation = [];
        // if (Array.isArray(arr)) {
        //   arr.forEach((finalStation) => {
        //     // console.log("finalStation", finalStation);
        //     _user.stations.forEach((userStation) => {
        //       // console.log("userStation", userStation);
        //       if (finalStation.id === userStation.id)
        //         validStation.push(finalStation);
        //     });
        //   });
        // }
        // if (validStation) setStationList(validStation);
      }
      setSelectedStation(_defaultStation);
    });
  }, []);

  const sendWhatsappMessage = (message, dataObj) => {
    setIsSending(true);
    let phone = dataObj._id.customer.whatsapp;

    SendMessage(phone, message).then((res) => {
      console.log("🚀 ~ SendMessage ~ res:", res);
      if (res && res.status === 200) {
        // console.log(res.data.message);
        const packageDetail = dataObj.packageDetail;
        // console.log(packageDetail);

        for (let index = 0; index < packageDetail.length; index++) {
          // console.log(`index: ${index} data.lengthL: ${data.packageDetail.length - 1}` )
          PatchPackageSomeInfo(
            packageDetail[index]._id,
            packageDetail[index].tracking,
            selectedStation.id,
            selectedStation.parcelStatus,
            packageDetail[index].remark,
            false,
            true
          ).then((res) => {
            if (res.status === 200 && index === packageDetail.length - 1) {
              playCorrectSound();
              deleteRow(filteredRows, dataObj._id.customer.id);
              Swal.fire({
                text: "Whatsapp sending success",
                icon: "success",
                position: "top-right",
                timer: 1200,
                toast: true,
                showConfirmButton: false,
              });
              console.log(`Packages has been updated`);
            }
            if (res.status !== 200) {
              playAlertSound();
              console.log(res.message);
            }
          });
        }
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error",
          icon: "error",
          text: res.data.message,
          timer: 3000,
        });
        setIsSending(false);
      }
      setIsSending(false);
    });
  };

  const updatePackage = (rows, dataObj) => {
    playAlertSound();
    Swal.fire({
      title: `ສົ່ງຂໍ້ຄວາມແຈ້ງ ${dataObj._id.customer.name} ແລ້ວບໍ?`,
      text: `Have you notified ${dataObj._id.customer.name} yet?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: " Yes ",
      cancelButtonText: " Not yet ",
    }).then((result) => {
      if (result.isConfirmed) {
        const packageDetail = dataObj.packageDetail;
        for (let index = 0; index < packageDetail.length; index++) {
          PatchPackageSomeInfo(
            packageDetail[index]._id,
            packageDetail[index].tracking,
            selectedStation.id,
            selectedStation.parcelStatus,
            packageDetail[index].remark,
            false,
            true
          ).then((res) => {
            if (
              res.status === 200 &&
              index === dataObj.packageDetail.length - 1
            ) {
              playCorrectSound();
              deleteRow(rows, dataObj._id.customer.id);
            }
            if (res.status !== 200) {
              console.log(res);
            }
          });
        }
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      valueGetter: (params) => params.row._id.customer._id,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.7,
      valueGetter: (params) => params.row._id.customer.name,
    },
    {
      field: "whatsapp",
      headerName: "Whatsapp",
      flex: 1,
      valueGetter: (params) => params.row._id.customer.whatsapp,
    },

    {
      field: "smsMessage",
      headerName: "Message",
      flex: 3,
      hide: isMobile,
    },
    {
      field: "totalPackages",
      headerName: "item(s).",
      flex: 0.5,
    },
    {
      field: "totalShippingFee",
      headerName: "Fee",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "copy",
      headerName: "Copy",
      flex: 0.5,
      hide: isMobile,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              copyTextToClipboard(cellValues.row.smsMessage);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ContentCopyIcon />
          </IconButton>
        );
      },
    },
    {
      field: "send",
      headerName: "Send",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            disabled={isSending}
            onClick={() => {
              sendWhatsappMessage(cellValues.row.smsMessage, cellValues.row);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <SendIcon />
          </IconButton>
        );
      },
    },
    {
      field: "success",
      headerName: "Success",
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outlined"
            onClick={() => {
              updatePackage(filteredRows, cellValues.row);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <CheckIcon />
          </IconButton>
        );
      },
    },
  ];

  // Conditionally filter columns based on isMobile state
  const filteredColumns =
    _user.role === "agent"
      ? columns.filter((column) => column.field !== "send")
      : columns;

  return (
    <Box m="0 1rem">
      <Grid container spacing={2}>
        <Grid item xl={12} md={12} xs={12}>
          <Header
            title="WhatsApp"
            subtitle="ສົ່ງຂໍ້ຄວາມແຈ້ງເຕືອນທາງ WhatsApp ໃຫ້ລູກຄ້າຮູ້ວ່າມີພັດສະດຸມາຮອດສາຂາປາຍທາງແລ້ວ"
          />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <FlexBetween>
            {/* Scan package section */}
            <Grid container spacing={2}>
              <Grid item xl={4} md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Select station</InputLabel>
                  <Select
                    label="Select station"
                    value={selectedStation ? selectedStation.id : ""}
                    onChange={(e) => {
                      GetStationDetailById(e.target.value).then((res) => {
                        if (res.status === 200) {
                          setSelectedStation(res.data);
                        }
                      });
                    }}
                    required
                  >
                    {stationList &&
                      stationList.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {id} - {name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </FlexBetween>
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          <Divider />
        </Grid>
        <Grid item xl={12} md={12} xs={12}>
          {/*  Data Grid  */}
          <Box
            // m={isMobile ? " 0 -0.5rem" : "0"}
            mt={-2}
            height="74vh"
            // width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
              marginLeft: { isMobile },
            }}
          >
            <DataGrid
              loading={isLoading}
              getRowId={(row) => row._id.customer._id}
              rows={filteredRows || []}
              columns={filteredColumns}
              rowCount={filteredRows ? filteredRows.length : 0}
              rowsPerPageOptions={[20, 50, 100]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              sortingMode="client"
              filterMode="client"
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onSortModelChange={(newSortModel) => setSort(...newSortModel)}
              components={{ Toolbar: DataGridCustomToolbarClientMode }}
              componentsProps={{
                toolbar: { search, setSearch },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhatsApp;
