import { api } from "./config";

export async function SendMessage(phone, message) {
  try {
    const response = await api().post("/whatsapp/sendmessage/" + phone, {
      message,
    })
    .then((res) => res)
    .catch((error) => error.response);

    return response;
  } catch (error) {
    console.log(error);
  }
}
