import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlLabel,
  Switch,
  useMediaQuery,
  InputBase,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Avatar,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import {
  ClearOutlined,
  CloseOutlined,
  Edit,
  ModeEditOutlineOutlined,
  Search,
} from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import { GetCustomersList } from "api/customers";
import { GetRouteList, GetRouteDetail } from "api/routes";
import { GetPackageList, DeletePackage, PatchPackage } from "api/packages";
import Swal from "sweetalert2";
import {
  getScanText,
  isAgentUser,
  playAlertSound,
  playCorrectSound,
  playIncorrectSound,
} from "utils";
import { isMobile } from "react-device-detect";
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import { GetAgentList } from "api/agent";
import { GetStationDetailById } from "api/stations";
import { user } from "localStorage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PackageList = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Data to send to back-end
  const [packageId, setPackageId] = useState("");
  const [tracking, setTracking] = useState("");
  const [description, setDescription] = useState("");
  const [orderId, setOrderId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [routeId, setRouteId] = useState("");
  const [customer, setCustomer] = useState(null);
  const [shippingFee, setShippingFee] = useState(0);
  const [status, setStatus] = useState("");
  const [station, setStation] = useState("");
  const [currentStation, setCurrentStation] = useState(null);
  const [whatsappStatus, setWhatsappStatus] = useState(false);

  const [remark, setRemark] = useState("");
  const [amount, setAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isDeliveryRequired, setIsDeliveryRequired] = useState(false);
  const [isCOD, setIsCOD] = useState(false);
  const [isSubCustomer, setIsSubCustomer] = useState(false);
  const [level, setLevel] = useState("1");
  const [agentList, setAgentList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [isForwardRequired, setIsForwardRequired] = useState(false);
  const [isSelfOrder, setIsSelfOrder] = useState(false);
  const [finalShippingFee, setFinalShippingFee] = useState(0);
  const [agentWhatsappStatus, setAgentWhatsappStatus] = useState(false);

  // Data for front-end
  const [searchCustomer, setSearchCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [stationList, setStationList] = useState(null);
  const [isNewCustomerSelected, setIsNewCustomerSelected] = useState(false);

  const _user = user();
  const isAgentUser = _user.role === "agent";

  // values to set in DataGrid
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  function RefreshPackageList(page, pageSize, sort, search) {
    setIsLoading(true);
    GetPackageList(page, pageSize, sort, search).then((res) => {
      if (res.status === 200) {
        if (isMobile) {
          setRows((prevRows) => {
            const newRows = res.data.packages.filter(
              (newItem) =>
                !prevRows.some(
                  (existingItem) => existingItem._id === newItem._id
                )
            );
            return [...prevRows, ...newRows];
          });
        } else {
          setRows(res.data.packages);
        }
        setTotalCount(res.data.total);
      } else {
        console.log(res.message);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (isDeliveryRequired) {
      setIsForwardRequired(false);
    }
  }, [isDeliveryRequired]);

  useEffect(() => {
    if (isForwardRequired) {
      setIsDeliveryRequired(false);
      setRemark(customer.remark);
    } else {
      setRemark("");
    }
  }, [isForwardRequired]);

  useEffect(() => {
    // setSearchCustomer("");
    GetRouteList().then((res) => {
      setRouteList(res.data);
    });
  }, []);
  useEffect(() => {
    if (routeId) {
      GetRouteDetail(routeId).then((res) => {
        if (res.status === 200) {
          setStationList(res.data.navigator);
        }
      });
    }
  }, [routeId]);

  useEffect(() => {
    GetCustomersList(searchCustomer, undefined, undefined, undefined).then(
      (res) => {
        if (res.status === 200) {
          setCustomerList(res.data.customers);
          let selectedCustomer = res.data.customers.filter((obj) => {
            return obj.name === searchCustomer;
          });
          // Set customer if selectedCustomer is not empty
          if (Object.keys(selectedCustomer).length !== 0) {
            setCustomer(selectedCustomer[0]);
          } else {
            setCustomer(null);
          }
        } else {
          console.log(res.message);
        }
      }
    );
  }, [searchCustomer]);

  useEffect(() => {
    if (isNewCustomerSelected && customer) {
      if ("default_route" in customer) setRouteId(customer.default_route);
      if ("isCOD" in customer) setIsCOD(customer.isCOD);
      if ("isDeliveryRequired" in customer)
        setIsDeliveryRequired(customer.isDeliveryRequired);
      if ("isForwardRequired" in customer)
        setIsForwardRequired(customer.isForwardRequired);
      if ("isSubCustomer" in customer) setIsSubCustomer(customer.isSubCustomer);
      if ("agentId" in customer) setAgentId(customer.agentId);
      if ("level" in customer) setLevel(customer.level);
      if ("isSelfOrder" in customer) setIsSelfOrder(customer.isSelfOrder);
      setIsNewCustomerSelected(false);
    }
  }, [customer]);

  useEffect(() => {
    if (isSubCustomer) {
      GetAgentList().then((res) => {
        if (res.data) {
          setAgentList(res.data);
        }
      });
    } else {
      setAgentId("");
    }
  }, [isSubCustomer]);

  const buttonEditHandler = (packageItem) => {
    const {
      _id,
      tracking,
      description,
      orderId,
      customer,
      amount,
      quantity,
      shippingFee,
      routeId,
      station,
      status,
      remark,
      paymentStatus,
      whatsappStatus,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isSubCustomer,
      agentId,
      level,
      isSelfOrder,
      finalShippingFee,
      agentWhatsappStatus,
    } = packageItem;
    setSearchCustomer(customer && customer.name);
    setPackageId(_id);
    setTracking(tracking);
    setDescription(description);
    setOrderId(orderId);
    setCustomer(customer);
    setAmount(amount);
    setQuantity(quantity);
    setShippingFee(shippingFee);
    setStatus(status);
    setRemark(remark);
    setPaymentStatus(paymentStatus);
    setStation(station);
    setWhatsappStatus(whatsappStatus);
    setRouteId(routeId);
    setEditDialogOpen(true);
    setIsCOD(isCOD);
    setIsDeliveryRequired(isDeliveryRequired);
    setIsForwardRequired(isForwardRequired);
    setIsSubCustomer(isSubCustomer);
    setAgentId(agentId);
    setLevel(level);
    setIsSelfOrder(isSelfOrder);
    setFinalShippingFee(finalShippingFee ?? 0);
    setAgentWhatsappStatus(agentWhatsappStatus);

    GetStationDetailById(station).then((res) => {
      if (res && res.status === 200) {
        setCurrentStation(res.data);
      }
    });
  };

  const buttonDeleteHandler = (event, cellValues) => {
    console.log("delete button clicked");
    Swal.fire({
      title: `Delete ${cellValues.row.tracking} ?`,
      text: "The status of this package will be changed to deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePackage(cellValues.row._id).then((res) => {
          if (res.status === 200) {
            // DELETE SELECTED ROW FROM ARRAY "data.packages"
            setRows(() => {
              return rows.filter((item) => item._id !== cellValues.id);
            });
            playCorrectSound();
            Swal.fire({
              title: "Deleted!",
              text: res.message,
              icon: "success",
              timer: 1500,
            });
          } else if (res.status === 400) {
            playAlertSound();
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: `Status ${res.status} - ${res.data.message}`,
              showConfirmButton: true,
            });
          } else {
            playIncorrectSound();
            Swal.fire({
              backdrop: true,
              toast: false,
              icon: "error",
              text: res.message,
              showConfirmButton: true,
            });
          }
        });
      }
    });
  };

  const handleFormSubmited = (e) => {
    e.preventDefault();

    let customerId = customer ? customer.id : "";
    PatchPackage(
      packageId,
      tracking,
      description,
      orderId,
      customerId,
      amount,
      quantity,
      shippingFee,
      routeId,
      station,
      status,
      remark,
      paymentStatus,
      whatsappStatus,
      isCOD,
      isDeliveryRequired,
      isForwardRequired,
      isSubCustomer,
      agentId,
      level,
      isSelfOrder,
      finalShippingFee,
      agentWhatsappStatus
    ).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        // update object data in rows
        let objIndex = rows.findIndex((obj) => obj._id === packageId);
        rows[objIndex].tracking = tracking;
        rows[objIndex].description = description;
        rows[objIndex].orderId = orderId;
        rows[objIndex].customer = customer;
        rows[objIndex].amount = amount;
        rows[objIndex].quantity = quantity;
        rows[objIndex].shippingFee = shippingFee;
        rows[objIndex].routeId = routeId;
        rows[objIndex].station = station;
        rows[objIndex].status = status;
        rows[objIndex].remark = remark;
        rows[objIndex].paymentStatus = paymentStatus;
        rows[objIndex].whatsappStatus = whatsappStatus;
        rows[objIndex].isCOD = isCOD;
        rows[objIndex].isDeliveryRequired = isDeliveryRequired;
        rows[objIndex].isForwardRequired = isForwardRequired;
        rows[objIndex].isSubCustomer = isSubCustomer;
        rows[objIndex].agentId = agentId;
        rows[objIndex].level = level;
        rows[objIndex].isSelfOrder = isSelfOrder;
        rows[objIndex].finalShippingFee = finalShippingFee;

        setEditDialogOpen(false);
        playCorrectSound();
        Swal.fire({
          title: "Updated!",
          icon: "success",
          text: res.message,
          timer: 1500,
        });
      } else if (res.status === 400) {
        playAlertSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: `Status ${res.status} - ${res.data.message}`,
        });
      } else {
        playIncorrectSound();
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: res.message,
        });
      }
    });
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 0.5,
      valueGetter: (params) => params.row.customer && params.row.customer.name,
    },
    {
      field: "tracking",
      headerName: "Tracking",
      flex: 0.5,
    },
    {
      field: "orderId",
      headerName: "Order ID",
      flex: 0.5,
      hide: isMobile,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "routeId",
      headerName: "Route ID",
      flex: 0.5,
      hide: isMobile,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
      hide: true,
    },
    // {
    //   field: "quantity",
    //   headerName: "QTY",
    //   flex: 0.5,
    // },

    {
      field: "status",
      headerName: "Status",
      flex: 0.3,
      hide: true,
    },
    {
      field: "station",
      headerName: "Station",
      flex: 0.3,
      hide: isMobile,
    },
    {
      field: "isCOD",
      headerName: "COD",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isCOD ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "shippingFee",
      headerName: "ຄ່າບໍລິການ",
      flex: 0.3,
    },
    {
      field: "isForwardRequired",
      headerName: "ສົ່ງຕໍ່",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isForwardRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isDeliveryRequired",
      headerName: "ສົ່ງຢູ່ບ້ານ",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isDeliveryRequired ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isSelfOrder",
      headerName: "ສັ່ງເອງ",
      flex: 0.3,
      hide: isMobile,
      renderCell: (cellValues) => {
        return cellValues.row.isSelfOrder ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "isSubCustomer",
      headerName: "ລູກຄ້າຕົວແທນ",
      flex: 0.3,
      hide: false,
      renderCell: (cellValues) => {
        return cellValues.row.isSubCustomer ? (
          <CheckBoxIcon color="secondary" />
        ) : (
          <DoNotDisturbIcon color="primary" />
        );
      },
    },
    {
      field: "agentId",
      headerName: "ຕົວແທນ",
      flex: 0.3,
      hide: false,
    },
    {
      field: "finalShippingFee",
      headerName: "Final Fee",
      flex: 0.3,
      hide: false,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.7,
      hide: isMobile,
      valueGetter: (params) =>
        moment(params.row.updatedAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.7,
      hide: true,
      valueGetter: (params) =>
        moment(params.row.createdAt)
          .tz("Asia/Vientiane")
          .format("DD/MM/YYYY, h:mm:ss A"),
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 0.2,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outline"
            onClick={() => {
              buttonEditHandler(cellValues.row);
            }}
            sx={{ "&:hover": { color: theme.palette.warning.main } }}
          >
            <ModeEditOutlineOutlined />
          </IconButton>
        );
      },
    },
    {
      field: "del",
      headerName: "Delete",
      flex: 0.2,
      hide: true,
      renderCell: (cellValues) => {
        return (
          <IconButton
            variant="outline"
            sx={{ "&:hover": { color: theme.palette.error.main } }}
            onClick={(event) => buttonDeleteHandler(event, cellValues)}
          >
            <ClearOutlined />
          </IconButton>
        );
      },
    },
  ];

  let timer;
  const setSearchWithDelaying = (val) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setSearch(val);
    }, 1000);
  };

  useEffect(() => {
    if (!isMobile) return;
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 1;
      if (bottom && !isLoading && rows.length < totalCount) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, rows.length, totalCount]);

  useEffect(() => {
    RefreshPackageList(page, pageSize, sort, search);
  }, [page, pageSize, sort, search]);

  useEffect(() => {
    setRows([]);
    setPage(0);
    RefreshPackageList(0, pageSize, sort, search);
  }, [search]);

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isMobile ? (
        // If non-mobile display as DataGrid
        <Box
          m="-1.5rem -1.5rem 0 -1.5rem"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading}
            getRowId={(row) => row._id}
            rows={rows || []}
            columns={columns}
            rowCount={totalCount || 0}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            sortingMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSortModelChange={(newSortModel) => setSort(...newSortModel)}
            components={{ Toolbar: DataGridCustomToolbar }}
            componentsProps={{
              toolbar: { searchInput, setSearchInput, setSearch },
            }}
          />
        </Box>
      ) : (
        // If mobile display as card item
        <Box m="0 -1.5rem">
          <Grid container spacing={2} width="auto">
            <Grid item xl={4} md={6} xs={12}>
              <FlexBetween
                backgroundColor={theme.palette.background.alt}
                borderRadius="9px"
                gap="3rem"
                p="0.7rem 1.5rem"
              >
                <InputBase
                  placeholder="Search..."
                  onChange={(e) => setSearchWithDelaying(e.target.value)}
                />

                <IconButton>
                  <Search />
                </IconButton>
              </FlexBetween>
            </Grid>
            {rows &&
              rows.map((packageItem, index) => (
                <Grid item key={index} xl={4} md={6} xs={12} width="auto">
                  <Card
                    sx={{
                      background: theme.palette.grey[100],
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{
                            bgcolor:
                              parseInt(packageItem.shippingFee) !== 0
                                ? theme.palette.secondary[400]
                                : theme.palette.grey[500],
                          }}
                          aria-label="recipe"
                        >
                          {isAgentUser
                            ? packageItem.finalShippingFee
                            : packageItem.shippingFee}
                        </Avatar>
                      }
                      title={packageItem.tracking}
                      subheader={moment(packageItem.updatedAt)
                        .tz("Asia/Vientiane")
                        .format("DD/MM/YYYY, hh:mm:ss")}
                      action={
                        <FormControl sx={{ mr: 1, mt: 0.5 }}>
                          <Typography
                            variant="body1"
                            color={theme.palette.secondary[200]}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {packageItem.customer && packageItem.customer.name}
                            {packageItem.isSubCustomer &&
                              ` - (${packageItem.agentId})`}
                          </Typography>
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[500]}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {packageItem.customer &&
                              packageItem.customer.whatsapp}
                          </Typography>
                        </FormControl>
                      }
                    />
                    <CardContent>
                      <FlexBetween sx={{ marginTop: -2 }}>
                        <Typography variant="body2" color="text.secondary">
                          Order ID:{" "}
                          {packageItem.orderId
                            ? packageItem.orderId
                            : "NA / ບໍ່ມີ"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          at {packageItem.station} ({packageItem.routeId})
                        </Typography>
                      </FlexBetween>

                      <Typography variant="body2" color="text.secondary">
                        {packageItem.description}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.secondary[300]}
                      >
                        {packageItem.remark
                          ? "Remark: " + packageItem.remark
                          : ""}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ flexGrow: 1, marginLeft: 1 }}>
                        <Typography color={theme.palette.grey[500]}>
                          #{index + 1}
                        </Typography>
                      </Box>
                      <IconButton
                        aria-label="Edit package"
                        onClick={() => buttonEditHandler(packageItem)}
                      >
                        <Edit />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}

      {/* Edit Package Dialog  */}
      <Box>
        <Dialog
          open={editDialogOpen}
          TransitionComponent={Transition}
          maxWidth="sm"
          fullScreen={isMobile}
          keepMounted
          onClose={() => {
            setEditDialogOpen(false);
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <form onSubmit={handleFormSubmited}>
            <DialogTitle>
              Edit Package
              <IconButton
                aria-label="close"
                onClick={() => setEditDialogOpen(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1} mt={2}>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      onInput={(e) => setSearchCustomer(e.target.value)}
                      onChange={(e, value) => {
                        if (value) {
                          setIsNewCustomerSelected(true);
                          setCustomer(value);
                        }
                      }}
                      disablePortal
                      id="combo-box-customer"
                      options={customerList}
                      value={customer}
                      isOptionEqualToValue={(option, value) => {
                        return value && option.id === value.id;
                      }}
                      getOptionLabel={(option) =>
                        option.name + " - " + option.whatsapp || ""
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Customer" />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Route</InputLabel>
                    <Select
                      id="route"
                      value={!routeId ? "" : routeId}
                      label="Route"
                      onChange={(event) => setRouteId(event.target.value)}
                      required
                    >
                      {routeList &&
                        routeList.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={id}>
                              {id} ({name})
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <InputLabel>
                    <FlexBetween>
                      <Typography>Station</Typography>
                      <Typography>Next Station</Typography>
                    </FlexBetween>
                  </InputLabel>
                  <Select
                    fullWidth
                    value={stationList ? station : ""}
                    onChange={(e) => setStation(e.target.value)}
                    // disabled={currentStation.finalStation}
                  >
                    {stationList &&
                      stationList.map(
                        ({ _id, station, stationName, nextStationName }) => {
                          return (
                            <MenuItem key={_id} value={station}>
                              <Grid container>
                                <Grid item xl={5} md={5} xs={5}>
                                  {stationName}
                                </Grid>
                                <Grid item xl={2} md={2} xs={2}>
                                  {" => "}
                                </Grid>
                                <Grid item xl={5} md={5} xs={5}>
                                  {nextStationName ? nextStationName : ""}
                                </Grid>
                              </Grid>
                            </MenuItem>
                          );
                        }
                      )}
                  </Select>
                </Grid>
                <Grid item xl={3} md={3} xs={6}>
                  <InputLabel>Whatsapp Status</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={whatsappStatus || false}
                        onChange={() => setWhatsappStatus(!whatsappStatus)}
                      />
                    }
                    label={whatsappStatus ? "ສົ່ງແລ້ວ" : "ຍັງບໍ່ໄດ້ສົ່ງ"}
                  />
                </Grid>
                <Grid item xl={3} md={3} xs={6}>
                  <InputLabel>Delivery Status</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={paymentStatus}
                        onChange={() => setPaymentStatus(!paymentStatus)}
                      />
                    }
                    label={paymentStatus ? "ສົ່ງແລ້ວ" : "ຍັງບໍ່ໄດ້ສົ່ງ"}
                  />
                </Grid>
                <Grid item xl={3} md={6} xs={6}>
                  <InputLabel>COD ຈ່າຍປາຍທາງ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isCOD}
                        onChange={() => setIsCOD(!isCOD)}
                      />
                    }
                    label={isCOD ? "ເກັບ" : "ບໍ່ເກັບ"}
                  />
                </Grid>
                <Grid item xl={3} md={6} xs={6}>
                  <InputLabel>Delivery ສົ່ງຮອດເຮືອນ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isDeliveryRequired}
                        onChange={() =>
                          setIsDeliveryRequired(!isDeliveryRequired)
                        }
                      />
                    }
                    label={isDeliveryRequired ? "ຈັດສົ່ງ" : "ບໍ່ຈັດສົ່ງ"}
                  />
                </Grid>

                <Grid item xl={3} md={6} xs={6}>
                  <InputLabel>ຝາກນຳຂົນສົ່ງຕໍ່</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!customer}
                        color="secondary"
                        checked={isForwardRequired}
                        onChange={() =>
                          setIsForwardRequired(!isForwardRequired)
                        }
                      />
                    }
                    label={isForwardRequired ? "ຝາກ" : "ບໍ່ຝາກ"}
                  />
                </Grid>
                <Grid item xl={3} md={6} xs={6}>
                  <InputLabel>ລູກຄ້າສັ່ງເຄື່ອງເອງ</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={isSelfOrder}
                        onChange={() => setIsSelfOrder(!isSelfOrder)}
                      />
                    }
                    label={isSelfOrder ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                  />
                </Grid>
                {!isAgentUser && (
                  <Grid item xl={3} md={6} xs={6}>
                    <InputLabel>ລູກຄ້າຂອງຕົວແທນ</InputLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          color="secondary"
                          checked={isSubCustomer}
                          onChange={() => setIsSubCustomer(!isSubCustomer)}
                        />
                      }
                      label={isSubCustomer ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                    />
                  </Grid>
                )}
                {!isAgentUser && isSubCustomer && (
                  <>
                    <Grid item xl={3} md={6} xs={6}>
                      <InputLabel>ສົ່ງແຈ້ງຕົວແທນແລ້ວ</InputLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            color="secondary"
                            checked={agentWhatsappStatus}
                            onChange={() =>
                              setAgentWhatsappStatus(!agentWhatsappStatus)
                            }
                          />
                        }
                        label={
                          agentWhatsappStatus ? "ສົ່ງແລ້ວ" : "ຍັງບໍ່ໄດ້ສົ່ງ"
                        }
                      />
                    </Grid>
                    <Grid item xl={3} md={6} xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>ເລືອກຕົວແທນ Agent</InputLabel>
                        <Select
                          id="agent"
                          value={!agentId ? "" : agentId}
                          label="ເລືອກຕົວແທນ Agent"
                          onChange={(event) => setAgentId(event.target.value)}
                          required={true}
                        >
                          {agentList &&
                            agentList.map(({ id, name }) => {
                              return (
                                <MenuItem key={id} value={id}>
                                  {id} - {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xl={3} md={6} xs={6}>
                      <FormControl fullWidth>
                        <InputLabel>Final Shipping Fee</InputLabel>
                        <OutlinedInput
                          value={finalShippingFee}
                          type="number"
                          label="Final Shipping Fee"
                          id="finalShippingFee"
                          onChange={(e) => setFinalShippingFee(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid mb={2} item xl={12} md={12} xs={12}>
                  <Divider />
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Tracking Number</InputLabel>
                    <OutlinedInput
                      label="Tracking Number"
                      id="tracking"
                      value={tracking}
                      required={true}
                      onChange={(e) => setTracking(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          setTracking(getScanText(tracking));
                          document.getElementById("shippingFee").select();
                          document.getElementById("shippingFee").focus();
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Order ID</InputLabel>
                    <OutlinedInput
                      type="text"
                      label="Order ID"
                      value={orderId}
                      id="orderId"
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Description"
                      value={description}
                      id="decscription"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xl={12} md={12} xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      maxRows={5}
                      label="Remark"
                      value={remark}
                      id="remark"
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Amount</InputLabel>
                    <OutlinedInput
                      type="number"
                      label="Amount"
                      value={amount}
                      id="amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} md={4} xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Quantity</InputLabel>
                    <OutlinedInput
                      type="number"
                      value={quantity}
                      label="Quantity"
                      id="quantity"
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                {isAgentUser ? (
                  <Grid item xl={4} md={4} xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Final Shipping Fee</InputLabel>
                      <OutlinedInput
                        value={finalShippingFee}
                        type="number"
                        label="Final Shipping Fee"
                        id="finalShippingFee"
                        onChange={(e) => setFinalShippingFee(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xl={4} md={4} xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Shipping Fee</InputLabel>
                      <OutlinedInput
                        value={shippingFee}
                        type="number"
                        label="Shipping Fee"
                        id="shippingFee"
                        onChange={(e) => setShippingFee(e.target.value)}
                        disabled={isAgentUser}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={3}
                mb={2}
                mr={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Grid item xl={3} md={3} xs={4}>
                  <FormControl fullWidth>
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="secondary"
                    >
                      Save
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
    </Box>
  );
};

export default PackageList;
